import { render, staticRenderFns } from "./Lesson9.vue?vue&type=template&id=7a44e9f0&scoped=true&"
import script from "./Lesson9.vue?vue&type=script&lang=js&"
export * from "./Lesson9.vue?vue&type=script&lang=js&"
import style0 from "./Lesson9.vue?vue&type=style&index=0&id=7a44e9f0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a44e9f0",
  null
  
)

export default component.exports